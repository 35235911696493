import { FC, useEffect } from 'react';
import Banner from './Banner';
import '../styles/App.scss';
import Page from './Page';
import useMousePosition from '../hooks/useMousePosition';

const App: FC = () => {
	return (
		<div className="App">
			<div className="wip">
				<p>WORK IN</p>
				<p>PROGRESS</p>
			</div>
			<Banner />
			<Page />
			<Cursor />
		</div>
	);
}

const Cursor: FC = () => {
	const wildCSS = document.querySelector<HTMLElement>('*');
	const { x, y } = useMousePosition();
	const size = 50;

	const isTouchDevice = () => {
		return (('ontouchstart' in window) ||
			(navigator.maxTouchPoints > 0));
	}

	useEffect(() => {
		if (isTouchDevice()) {
			document.querySelectorAll('*').forEach(element => {
				(element as HTMLElement).style.cursor = "default"
			})
		}
	}, [wildCSS])

	return (
		<div
			id="cursor"
			style={{
				width: size,
				height: size,
				top: isTouchDevice() ? 0 : y - (size / 2) || 0,
				left: isTouchDevice() ? 0 : x - (size / 2) || 0,
				display: isTouchDevice() ? 'none' : 'block',
			}}
		>
		</div>
	);
}

export default App;