
import { useState, useEffect } from "react";

const useScrolled = () => {
    const [scrolled, setScrolled] = useState(window.pageYOffset === 0 ? false : true);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, []);

    const handleScroll = (event: Event) => {
        const scrollPos = window.pageYOffset;
        if (scrollPos > 0) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    }


    return scrolled
};

export default useScrolled;