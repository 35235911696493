import { FC, useState } from "react";
import "../styles/Contact.scss";
import wave from "../assets/res/wave.png";
import { DownloadIcon } from "./Svg";
import linkedin from "../assets/res/footer/linkedin.svg";
import github from "../assets/res/footer/github.svg";

const Contact: FC = () => {

    return (
        <div className="Contact">
            <div className="cols">

                <div className="col col1">
                    <p className="text">Hello there! I've always found self publication a bit awkward, but lets go through this one together.</p>
                    <p className="text">I've scraped up many technical skills over the years, be it from web development, programming apps, hosting servers for tournaments, to digital art, 3D Modeling and Animation. If it uses a computer, I've probably looked into it.</p>
                    <div className="contactButtons">
                        <ResumeDownloadButton />
                        <a className="socialLink" target="_blank" href="https://www.linkedin.com/in/shehryar-abdul-aziz" rel="noreferrer">
                            <img
                                src={linkedin}
                                alt="" />
                        </a>
                        <a className="socialLink" target="_blank" href="https://github.com/dr-nyt" rel="noreferrer">
                            <img
                                src={github}
                                alt="" />
                        </a>
                    </div>

                    <CopyEmailButton />
                </div>

                <div className="col col2">
                    <div className="imageHolder">
                        <img src={wave} alt="wave" />
                    </div>
                </div>
            </div>
        </div >
    );
}

const ResumeDownloadButton = () => {
    const resumeDownloadLink = "https://drive.google.com/file/d/1_AFXHRS2yHHtgJ6mAkipfFsKsNnmRSFz/view?usp=sharing";
    const [hoverColor, setHoverColor] = useState("white");

    const handleMouseEnter = () => {
        setHoverColor("black");
    }
    const handleMouseLeave = () => {
        setHoverColor("white");
    }

    return (
        <a className="downloadResumeLink" href={resumeDownloadLink} rel="noreferrer" target="_blank">
            <button className="downloadResumeButton" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}
                style={{ color: hoverColor, background: hoverColor === "black" ? "white" : "black", border: "2px solid transparent" }}>
                <DownloadIcon className="downloadIcon" fill={hoverColor} />
                <p><span>DOWNLOAD</span> RESUME</p>
            </button>
        </a>
    );
}

const CopyEmailButton = () => {
    const email = "shehryar.dev@gmail.com";
    const [emailText, setEmailText] = useState(email);

    const copyEmail = () => {
        setEmailText("copied to clipboard.");
        navigator.clipboard.writeText(email);
        // window.open(`mailto:${email}`);
        setTimeout(() => {
            setEmailText(email)
        }, 2000)
    }

    return (
        <button
            className="copyEmailButton"
            onClick={copyEmail}
            style={{
                mixBlendMode: emailText === email ? 'difference' : 'normal',
                background: emailText === email ? 'transparent' : 'white',
                color: emailText === email ? 'white' : 'black',
            }}
        >
            <p>{emailText}</p>
        </button>
    );
}

export default Contact;