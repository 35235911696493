import { FC, Suspense, useRef } from "react";
import * as THREE from 'three';
import { Canvas, useFrame, useLoader } from '@react-three/fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import useMousePosition from "../hooks/useMousePosition";
import "../styles/Banner.scss";
import { Bloom, DepthOfField, EffectComposer, Vignette } from "@react-three/postprocessing";
import { Html, Stars, useProgress } from "@react-three/drei";
import { map } from "../lib/utils";

const Banner: FC = () => {
    return (
        <div className="Banner">
            <Canvas>
                <color attach="background" args={["black"]} />

                <EffectComposer>
                    <Bloom luminanceThreshold={0.5} luminanceSmoothing={3} height={300} />
                    <Vignette eskil={false} offset={0.1} darkness={1.5} />
                    <DepthOfField focusDistance={0.3} focalLength={1} bokehScale={1} height={480} />
                </EffectComposer>

                <Suspense fallback={<Loader />}>
                    <Stars radius={100} depth={1} count={10000} factor={4} saturation={100} fade />
                    <Model path="./assets/earth.gltf" scale={map(window.innerWidth, 0, 1920, 0.4, 0.5)} position={[0, 0, 0]} rotation={[0, 0.5, 0]} />
                    <ambientLight intensity={0.2} />
                    <directionalLight color="white" position={[3, 6, 4]} intensity={3} />
                </Suspense>
            </Canvas>
        </div>
    );
}

function Loader() {
    const { progress } = useProgress();
    return <Html center>{progress} % loaded</Html>
}

const Model: FC<ModelProps> = (props) => {
    const myMesh = useRef<THREE.Mesh>();
    const gltf = useLoader(GLTFLoader, props.path);
    const { x, y } = useMousePosition();

    useFrame(({ camera }) => {
        if (myMesh.current !== undefined) {
            const xOffset = -map(x, 0, window.innerWidth, -1, 1);
            const yOffset = -map(y, 0, window.innerHeight, -1, 1);
            const scrollOffset = map(window.pageYOffset, 0, window.innerHeight, 0, 1);

            // Mesh control
            const meshXOffset = xOffset * 0.2 + -2;
            const meshYOffset = yOffset * 0.2;

            myMesh.current.rotation.y = meshXOffset;
            myMesh.current.rotation.x = meshYOffset;
            myMesh.current.rotation.z = scrollOffset;
            myMesh.current.position.y = scrollOffset * 1.2;
            myMesh.current.position.z = scrollOffset * 2.5;

            // Camera control
            const cameraXOffset = xOffset * 0.05;
            const cameraYOffset = yOffset * 0.05;

            camera.rotation.y = cameraXOffset;
            camera.rotation.x = cameraYOffset + scrollOffset;
            // camera.rotation.z = scrollOffset;
        }
    });

    return (
        <primitive object={gltf.scene} scale={props.scale} position={props.position} rotation={props.rotation} ref={myMesh} />
    );
}

interface ModelProps {
    path: string,
    scale: number,
    position: number[],
    rotation: number[]
}

export default Banner;