import { FC, useState } from "react";
import "../styles/Page.scss";
import Contact from "./Contact";
import Footer from "./Footer";
import Header from "./Header";
import Navbar from "./Navbar";
import Projects from "./Projects";
// import Skills from "./Skills";

const Page: FC = () => {
    const [scrollBlock, setScrollBlock] = useState(false);

    return (
        <div className={`Page ${scrollBlock ? "stopScroll" : ""}`}>
            <Navbar setScrollBlock={setScrollBlock} />
            <Header />
            <div className="content">
                {/* <section>
                    <div className="sectionTitle"><h1>SKILLS<span></span></h1></div>
                    <Skills />
                </section> */}
                <section id="projects">
                    <div className="sectionTitle"><h1>MY WORK<span></span></h1></div>
                    <p className="sectionSubTitle">& SOME PERSONAL PROJECTS</p>
                    <Projects />
                </section>
                <section id="contact">
                    <div className="focusExtender"></div>
                    <div className="sectionSpanBG">
                        <div className="sectionTitle"><h1>MY CONTACT<span></span></h1></div>
                        <p className="sectionSubTitle">& SOME THINGS ABOUT ME</p>
                        <Contact />
                    </div>
                </section>
            </div>
            <Footer />
        </div>
    );
}

export default Page;