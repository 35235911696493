import { FC } from "react";
import "../styles/Skills.scss";

type svgAttr = {
    fill: string
    className: string
}

export const GithubIcon: FC<svgAttr> = ({ fill, className }) => {

    return (
        <svg fill={fill} className={className} height="21" viewBox="0 0 24 24" width="21" xmlns="http://www.w3.org/2000/svg"><path d="m12 .5c-6.63 0-12 5.28-12 11.792 0 5.211 3.438 9.63 8.205 11.188.6.111.82-.254.82-.567 0-.28-.01-1.022-.015-2.005-3.338.711-4.042-1.582-4.042-1.582-.546-1.361-1.335-1.725-1.335-1.725-1.087-.731.084-.716.084-.716 1.205.082 1.838 1.215 1.838 1.215 1.07 1.803 2.809 1.282 3.495.981.108-.763.417-1.282.76-1.577-2.665-.295-5.466-1.309-5.466-5.827 0-1.287.465-2.339 1.235-3.164-.135-.298-.54-1.497.105-3.121 0 0 1.005-.316 3.3 1.209.96-.262 1.98-.392 3-.398 1.02.006 2.04.136 3 .398 2.28-1.525 3.285-1.209 3.285-1.209.645 1.624.24 2.823.12 3.121.765.825 1.23 1.877 1.23 3.164 0 4.53-2.805 5.527-5.475 5.817.42.354.81 1.077.81 2.182 0 1.578-.015 2.846-.015 3.229 0 .309.21.678.825.56 4.801-1.548 8.236-5.97 8.236-11.173 0-6.512-5.373-11.792-12-11.792z" /></svg>
    );
}

export const WebsiteIcon: FC<svgAttr> = ({ fill, className }) => {

    return (
        <svg fill={fill} className={className} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512" spacing="preserve">
            <g>
                <g>
                    <g>
                        <path
                            d="M328.719,366.51c-2.021-2.438-5.031-3.844-8.198-3.844H191.479c-3.167,0-6.177,1.406-8.198,3.844
				c-2.031,2.438-2.865,5.646-2.292,8.76C196.323,458.333,225.771,512,256,512s59.677-53.667,75.01-136.729
				C331.583,372.156,330.75,368.948,328.719,366.51z"
                        />
                        <path
                            d="M499.833,178.083c-1.406-4.417-5.51-7.417-10.156-7.417H369.563c-3.01,0-5.885,1.271-7.906,3.51
				c-2.031,2.24-3.01,5.229-2.708,8.229c2.469,24.5,3.719,49.271,3.719,73.594s-1.25,49.094-3.719,73.594
				c-0.302,3,0.677,5.99,2.708,8.229c2.021,2.24,4.896,3.51,7.906,3.51h120.115c4.646,0,8.75-3,10.156-7.417
				C507.906,308.635,512,282.427,512,256S507.906,203.365,499.833,178.083z"
                        />
                        <path
                            d="M353.292,140.438c0.865,5.135,5.313,8.896,10.521,8.896h107.667c3.76,0,7.25-1.99,9.177-5.219
				c1.917-3.24,1.99-7.25,0.188-10.552C448.823,74.885,393.813,30.188,329.896,10.938c-4.115-1.271-8.646,0.135-11.385,3.521
				c-2.719,3.396-3.115,8.094-1,11.885C333.052,54.198,345.427,93.646,353.292,140.438z"
                        />
                        <path
                            d="M326.719,170.667H185.281c-5.448,0-10.021,4.104-10.604,9.521c-2.656,24.625-4.01,50.135-4.01,75.813
				s1.354,51.188,4.01,75.813c0.583,5.417,5.156,9.521,10.604,9.521h141.438c5.448,0,10.021-4.104,10.604-9.521
				c2.656-24.625,4.01-50.135,4.01-75.813s-1.354-51.188-4.01-75.813C336.74,174.771,332.167,170.667,326.719,170.667z"
                        />
                        <path
                            d="M40.521,149.333h107.667c5.208,0,9.656-3.76,10.521-8.896c7.865-46.792,20.24-86.24,35.781-114.094
				c2.115-3.792,1.719-8.49-1-11.885c-2.729-3.375-7.24-4.802-11.385-3.521C118.188,30.188,63.177,74.885,31.156,133.563
				c-1.802,3.302-1.729,7.313,0.188,10.552C33.271,147.344,36.76,149.333,40.521,149.333z"
                        />
                        <path
                            d="M150.344,337.823c2.031-2.24,3.01-5.229,2.708-8.229c-2.469-24.5-3.719-49.271-3.719-73.594s1.25-49.094,3.719-73.594
				c0.302-3-0.677-5.99-2.708-8.229c-2.021-2.24-4.896-3.51-7.906-3.51H22.323c-4.646,0-8.75,3-10.156,7.417
				C4.094,203.365,0,229.573,0,256s4.094,52.635,12.167,77.917c1.406,4.417,5.51,7.417,10.156,7.417h120.115
				C145.448,341.333,148.323,340.063,150.344,337.823z"
                        />
                        <path
                            d="M158.708,371.563c-0.865-5.135-5.313-8.896-10.521-8.896H40.521c-3.76,0-7.25,1.99-9.177,5.219
				c-1.917,3.24-1.99,7.25-0.188,10.552c32.021,58.677,87.031,103.375,150.948,122.625c1.01,0.313,2.042,0.458,3.073,0.458
				c3.177,0,6.25-1.427,8.313-3.979c2.719-3.396,3.115-8.094,1-11.885C178.948,457.802,166.573,418.354,158.708,371.563z"
                        />
                        <path
                            d="M471.479,362.667H363.813c-5.208,0-9.656,3.76-10.521,8.896c-7.865,46.792-20.24,86.24-35.781,114.094
				c-2.115,3.792-1.719,8.49,1,11.885c2.063,2.552,5.135,3.979,8.313,3.979c1.021,0,2.063-0.146,3.073-0.458
				c63.917-19.25,118.927-63.948,150.948-122.625c1.802-3.302,1.729-7.313-0.188-10.552
				C478.729,364.656,475.24,362.667,471.479,362.667z"
                        />
                        <path
                            d="M183.281,145.49c2.021,2.438,5.031,3.844,8.198,3.844h129.042c3.167,0,6.177-1.406,8.198-3.844
				c2.031-2.438,2.865-5.646,2.292-8.76C315.677,53.667,286.229,0,256,0s-59.677,53.667-75.01,136.729
				C180.417,139.844,181.25,143.052,183.281,145.49z"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
}

export const PrivateIcon: FC<svgAttr> = ({ fill, className }) => {

    return (
        <svg fill={fill} className={className} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 477.859 477.859" spacing="preserve">
            <g>
                <g>
                    <path
                        d="M474.603,228.903c-28.995-38.012-63.834-71.186-103.219-98.287l-61.338,61.338
                c26.009,39.211,15.306,92.083-23.906,118.091c-28.545,18.934-65.653,18.931-94.195-0.007l-55.108,55.177
                c31.32,17.228,66.354,26.598,102.093,27.307c129.707,0,231.407-137.694,235.674-143.565
                C478.944,242.977,478.944,234.882,474.603,228.903z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        d="M238.93,85.321c-129.707,0-231.407,137.711-235.674,143.582c-4.341,5.98-4.341,14.074,0,20.053
                c28.995,38.012,63.834,71.186,103.219,98.287l61.338-61.338c-9.272-13.915-14.218-30.263-14.217-46.985
                c-0.076-47.053,38.007-85.258,85.059-85.334c16.81-0.027,33.253,4.92,47.258,14.217l55.108-55.177
                C309.702,95.4,274.669,86.029,238.93,85.321z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        d="M106.475,347.294L39.13,414.588c-6.669,6.664-6.672,17.472-0.009,24.141c6.664,6.669,17.472,6.672,24.141,0.009
                l73.574-73.523C126.398,359.797,116.262,353.815,106.475,347.294z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        d="M438.738,39.13c-6.664-6.669-17.472-6.672-24.141-0.009l-73.574,73.506c10.439,5.417,20.574,11.399,30.362,17.92
                l67.345-67.277C445.398,56.607,445.401,45.799,438.738,39.13z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        d="M285.914,167.804L167.813,285.906c6.346,9.58,14.552,17.786,24.132,24.132l118.101-118.084
                C303.696,182.373,295.491,174.161,285.914,167.804z"
                    />
                </g>
            </g>
        </svg>
    );
}

export const DownloadIcon: FC<svgAttr> = ({ fill, className }) => {
    return (
        <svg fill={fill} className={className} height="29" viewBox="0 0 29 29" width="29" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.5 28L28 17.5H20.515L20.5 1H8.5V17.5H1L14.5 28Z" stroke={fill} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.77282 17.3636V1.40906H20.6365V17.3636H27.7955L14.5001 28L1.40918 17.3636H8.77282Z" />
        </svg>
    );
}