import AngularLogo from "../assets/res/badges/angular.svg";
import AstroLogo from "../assets/res/badges/astro.svg";
import AWSLogo from "../assets/res/badges/aws.svg";
import BlenderLogo from "../assets/res/badges/blender.svg";
import DjangoLogo from "../assets/res/badges/django.svg";
import ElectronLogo from "../assets/res/badges/electron.svg";
import FirebaseLogo from "../assets/res/badges/firebase.svg";
import HerokuLogo from "../assets/res/badges/heroku.svg";
import IllustratorLogo from "../assets/res/badges/illustrator.svg";
import LinuxLogo from "../assets/res/badges/linux.svg";
import NextJsLogo from "../assets/res/badges/next-js.svg";
import PhotoshopLogo from "../assets/res/badges/photoshop.svg";
import PostgresqlLogo from "../assets/res/badges/postgresql.svg";
import PythonLogo from "../assets/res/badges/python.svg";
import ReactNativeLogo from "../assets/res/badges/react-native.svg";
import ReactLogo from "../assets/res/badges/react.svg";
import RubyOnRailsLogo from "../assets/res/badges/ruby-on-rails.svg";
import ThreeJsLogo from "../assets/res/badges/three-js.svg";
import TwitchLogo from "../assets/res/badges/twitch.svg";
import UnityLogo from "../assets/res/badges/unity.svg";
import VercelLogo from "../assets/res/badges/vercel.svg";

export const AngularBadge = () => <Badge src={AngularLogo} name="Angular" width="20px" height="20px" />
export const AstroBadge = () => <Badge src={AstroLogo} name="Astro" />
export const AWSBadge = () => <Badge src={AWSLogo} name="AWS" width="20px" />
export const BlenderBadge = () => <Badge src={BlenderLogo} name="Blender" />
export const DjangoBadge = () => <Badge src={DjangoLogo} name="Django" />
export const ElectronBadge = () => <Badge src={ElectronLogo} name="Electron" />
export const FirebaseBadge = () => <Badge src={FirebaseLogo} name="Firebase" />
export const HerokuBadge = () => <Badge src={HerokuLogo} name="Heroku" />
export const IllustratorBadge = () => <Badge src={IllustratorLogo} name="Illustrator" />
export const LinuxBadge = () => <Badge src={LinuxLogo} name="Linux" />
export const NextJsBadge = () => <Badge src={NextJsLogo} name="NextJs" />
export const PhotoshopBadge = () => <Badge src={PhotoshopLogo} name="Photoshop" />
export const PostgresqlBadge = () => <Badge src={PostgresqlLogo} name="Postgresql" />
export const PythonBadge = () => <Badge src={PythonLogo} name="Python" />
export const ReactNativeBadge = () => <Badge src={ReactNativeLogo} name="ReactNative" />
export const ReactBadge = () => <Badge src={ReactLogo} name="React" />
export const RubyOnRailsBadge = () => <Badge src={RubyOnRailsLogo} name="RubyOnRails" width="32px" />
export const ThreeJsBadge = () => <Badge src={ThreeJsLogo} name="ThreeJs" />
export const TwitchBadge = () => <Badge src={TwitchLogo} name="Twitch" width="15px" />
export const UnityBadge = () => <Badge src={UnityLogo} name="Unity" />
export const VercelBadge = () => <Badge src={VercelLogo} name="Vercel" />

type BadgeProps = {
    src: string;
    name: string;
    width?: string;
    height?: string;
}
const Badge = ({ src, name, width, height }: BadgeProps) => {
    return <img src={src} alt={name} style={{
        width: width || "18px",
        height: height || "18px",
        filter: "grayscale(0%)"
    }} />;;
}