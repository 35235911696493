import { FC } from "react";
import useScrolled from "../hooks/useScrolled";
import "../styles/Header.scss";
import arrow from "../assets/res/svg/arrow.svg";

const Header: FC = () => {
    const scrolled = useScrolled();

    return (
        <div className="Header" id="home" style={{ 'opacity': scrolled ? 0 : 1 }}>
            <div className="title">
                <p className="intro">Hello, this is</p>
                <h1 className="name">SHEHRYAR</h1>
                <p className="info">I love to code and design beautiful things.</p>
                <div className="callToAction">
                    <a href="#projects">
                        <button className="primaryButton">
                            MY WORK
                            <img className="mainArrow" src={arrow} alt="" />
                            <img src={arrow} alt="" />
                            <img src={arrow} alt="" />
                        </button>
                    </a>
                    <a href="#contact">
                        <button className="secondaryButton">
                            GET IN TOUCH
                            <img className="mainArrow" src={arrow} alt="" />
                            <img src={arrow} alt="" />
                            <img src={arrow} alt="" />
                        </button>
                    </a>
                </div>
            </div>

            <div className="scrollIcon">
                <div className="scrollDot"></div>
            </div>
        </div >
    );
}

export default Header;