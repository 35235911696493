import { FC } from "react";
import "../styles/Footer.scss";
import earthPoster from "../assets/res/earth_poster.png";
import signature from "../assets/res/footer/signature.png";
import linkedin from "../assets/res/footer/linkedin.svg";
import github from "../assets/res/footer/github.svg";
import Tilt from "react-parallax-tilt";
import useHover from "../hooks/useHover";

const Footer: FC = () => {
    const [hoverIn, hoverOut] = useHover();

    return (
        <div className="Footer">
            <div className="gift">
                <div className="giftTitle">
                    <h1>
                        A GIFT<span></span>
                    </h1>
                </div>
                <Tilt className="giftHolder" tiltMaxAngleX={3} tiltMaxAngleY={3} scale={1.05}>
                    <img src={earthPoster} className="giftImage" alt="" />
                    <div className="giftOverlay">
                        <div className="giftContent">
                            <div className="giftText">
                                <h1>DESKTOP WALLPAPERS</h1>
                                <p>Here is a gift before you leave, see you soon :D</p>
                            </div>
                            <div className="giftButtons">
                                {/* <button className="giftDownload">DOWNLOAD</button> */}
                                <button className="giftDownload">COMING SOON</button>
                                <p className="giftView"
                                    onMouseEnter={hoverIn}
                                    onMouseLeave={hoverOut}>
                                    VIEW WALLPAPERS
                                </p>
                            </div>
                        </div>
                    </div>
                </Tilt>
            </div>
            <div className="foot">
                <div className="footWrapper">
                    <div className="col col1">
                        <p>SIGNING OFF</p>
                        <img src={signature} alt="" />
                    </div>

                    <div className="col col2">
                        <a href="#home"
                            onMouseEnter={hoverIn}
                            onMouseLeave={hoverOut}>
                            BACK TO TOP
                        </a>
                    </div>

                    <div className="col col3">
                        <div className="links">
                            <a target="_blank" href="https://www.linkedin.com/in/shehryar-abdul-aziz" rel="noreferrer">
                                <img
                                    src={linkedin}
                                    alt=""
                                    onMouseEnter={hoverIn}
                                    onMouseLeave={hoverOut} />
                            </a>
                            <a target="_blank" href="https://github.com/dr-nyt" rel="noreferrer">
                                <img
                                    src={github}
                                    alt=""
                                    onMouseEnter={hoverIn}
                                    onMouseLeave={hoverOut} />
                            </a>
                        </div>

                        <p>Shehryar © 2021</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
