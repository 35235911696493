import { useState } from "react";

const useHover = () => {
    const setState = useState(false)[1];
    const cursor = document.getElementById("cursor");

    const hoverIn = () => {
        if (cursor) {
            cursor.style.background = "#0327d8";
        }
        setState(x => !x);
    }

    const hoverOut = () => {
        if (cursor) {
            cursor.style.background = "white";
        }
        setState(x => !x);
    }


    return [hoverIn, hoverOut];
};

export default useHover;