import { FC, useState } from "react";
import useHover from "../hooks/useHover";
import useScrolled from "../hooks/useScrolled";
import "../styles/Navbar.scss";
import { blockScrollType } from "../lib/utils";

const Navbar: FC<blockScrollType> = ({ setScrollBlock }) => {
    const [hoverIn, hoverOut] = useHover();
    const scrolled = useScrolled();

    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenuState = () => {
        setMenuOpen(false);
    };

    return (
        <div className="Navbar">
            <div className="navbarBG" style={{ opacity: scrolled || menuOpen ? 1 : 0 }}></div>
            <div className="bar">
                <div className="left">
                    <div className="menuIcon" onMouseEnter={hoverIn} onMouseLeave={hoverOut} onClick={toggleMenuState}>
                        <div className={`hamburger ${scrolled ? "hamburgerColor" : ""} ${menuOpen ? "menuOpen" : ""}`}></div>
                    </div>
                    <a href="#home" className={`title ${scrolled || menuOpen ? "linkState" : ""}`}>
                        SHEHRYAR
                    </a>
                </div>
                <div className="navLinks">
                    <a href="#home" className={`link ${scrolled ? "linkState" : ""}`} onMouseEnter={hoverIn} onMouseLeave={hoverOut}>
                        HOME
                    </a>
                    <a href="#projects" className={`link ${scrolled ? "linkState" : ""}`} onMouseEnter={hoverIn} onMouseLeave={hoverOut}>
                        PROJECTS
                    </a>
                    <a href="#contact" className={`link ${scrolled ? "linkState" : ""}`} onMouseEnter={hoverIn} onMouseLeave={hoverOut}>
                        CONTACT
                    </a>
                    <a
                        href="#contact"
                        className={"resume link " + (scrolled || menuOpen ? "resumeOffset" : "")}
                        onClick={toggleMenuState}
                    // onMouseEnter={hoverIn} onMouseLeave={hoverOut}
                    >
                        RESUME
                    </a>
                </div>
            </div>
            <div className={`menu ${menuOpen ? "menuState" : ""}`}>
                <ul className="menuLinks">
                    <li><a href="#home" onClick={toggleMenuState}>HOME</a></li>
                    <li><a href="#projects" onClick={toggleMenuState}>PROJECTS</a></li>
                    <li><a href="#contact" onClick={toggleMenuState}>CONTACT</a></li>
                </ul>
            </div>
        </div>
    );
};

export default Navbar;
